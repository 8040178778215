@keyframes grow {
  0% {
    width: 0;
  }
  100% {
    width: 1.75rem;
  }
}

.bullet {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  border-radius: 9999px;
}

.bullet.active {
  animation: grow 6s ease-in-out forwards;
  background-color: var(--theme-colors-primary);
}

.swiper:hover .bullet {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}
